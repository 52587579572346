const colors = [
  {
    name: "red",
    label: "Red",
    style: {
      "--color-primary": "#aa001e",
      "--class": "red"
    },
  },
  {
    name: "white",
    label: "White",
    style: {
      "--color-primary": "#ffffff",
      "--class": "white"
    },
  },
  {
    name: "beige",
    label: "Beige",
    style: {
      "--color-primary": "#E6C8A5",
      "--class": "beige"
    },
  },
  {
    name: "darkgrey",
    label: "Darkgrey",
    style: {
      "--color-primary": "#242424",
      "--class": "darkgrey"
    },
  },
  {
    name: "grey",
    label: "grey",
    style: {
      "--color-primary": "#dbe1e9",
      "--class": "grey"
    },
  },
];

export function GridSchema(props) {
  const schema = {
    title: "Schema",
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['image', 'alt', 'color',  'align', 'link', 'showButton', 'textButton'],
      },
    ],

    properties: {
      image: {
        title: 'Image',
        widget: 'object_browser',
        mode: 'image',
        allowExternals: true,
        return: 'single',
      },
      alt: {
        title: 'Image alt Text',
        type: 'string',
      },
      color: {
        title: 'Farbe',
        type: 'string',
        widget: 'color_picker',
        colors: colors
      },
      // textcolor: {
      //   title: 'Text Farbe',
      //   type: 'string',
      //   widget: 'color_picker',
      //   colors: textcolors
      // },
      align: {
        widget: 'align',
        title: "Align",
        actions: ['left', 'right'],
        default: 'left',
      },
      link: {
        title: 'Link auf',
        type: 'string',
      },
      showButton: {
        title: 'Button anzeigen',
        type: 'boolean',
      },
      textButton: {
        title: 'Button Text',
        type: 'string',
      },
    },
    required: [],
  }
  return schema;
};

export default GridSchema;
