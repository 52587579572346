import React from 'react';
import cx from 'classnames';
import { TextBlockView } from '@plone/volto-slate/blocks/Text';
import { DetachedTextBlockEditor } from '@plone/volto-slate/blocks/Text/DetachedTextBlockEditor';
import { Container as SemanticContainer, Button } from 'semantic-ui-react';
import config from '@plone/volto/registry';

const HighlightView = (props) => {
    const {
        data,
        isEditMode,
    } = props;


    const Container =
        config.getComponent({ name: 'Container' }).component || SemanticContainer;

    const styles = {
        border: data.color && "10px solid " + data.color["--color-primary"],
    };
    const space = {
        marginBottom: data.space && "2rem" 
    };

    return (
        <Container className="blockquote-container">
            <div style={styles} className='blockquote'>
                {isEditMode ? (
                    <DetachedTextBlockEditor {...props} />
                ) : (
                    <TextBlockView {...props} />
                )}
            </div >
            {data.text && (
                <div style={space} className='blockquote-caption'>
                    {data.text}
                </div>
            )}
        </Container>
    );
};

export default HighlightView;
