import icon from '@plone/volto/icons/quote.svg'
import BlockEdit from './Edit';
import BlockView from './View';
export default function applyConfig(config) {
    config.blocks.blocksConfig.info_kacheln = {
        id: 'info_kacheln',
        title: 'Info Kacheln',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
