import React, { useState, useEffect } from 'react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';

const View = (props) => {
    const { data, isEditMode } = props;
    if (!data) {
        return <div>missing data</div>;
    }

    const [cellWidth, setCellWidth] = useState("twelve")

    useEffect(() => {
        if (data.cells?.length % 2 === 0) {
            setCellWidth("six")
        }

        if (data.cells?.length % 3 === 0) {
            setCellWidth("four")
        }

        if (data.cells?.length % 4 === 0) {
            setCellWidth("three")
        }

    })

    return (
        <div className="columns-view info-kacheln-wrapper">
            <div className="ui twelve column grid column-grid">
                {data.cells?.map((cell, index) => ( // Added index as the second argument if you need it
                    <div key={index} className={`${cellWidth} wide computer twelve wide mobile six wide tablet column column-blocks-wrapper info-kacheln`}>
                        <ConditionalLink to={flattenToAppURL(cell.link)} condition={!isEditMode}>
                            <div className='tile'>

                                <div className='tile-content'>
                                    {cell.image && <Image src={cell.image['@id'] + '/@@images/image'} alt={cell.alt ? cell.alt : null} />}
                                    <h3>{cell.text}</h3>

                                </div>
                            </div>

                        </ConditionalLink>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default View;