import { defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import { mergeSchemas } from '../helpers';
import { v4 as uuid } from 'uuid';

const colors = [
  {
    name: "red",
    label: "Red",
    style: {
      "--color-primary": "#aa001e",
      "--color-secondary": "#ffffff",
      "--class": "red-light"
    },
  },
  {
    name: "beige",
    label: "Beige",
    style: {
      "--color-primary": "#E6C8A5",
      "--class": "beige-light"
    },
  },
  {
    name: "black",
    label: "Black",
    style: {
      "--color-primary": "#000000",
      "--color-secondary": "#ffffff",
      "--class": "black"
    },
  },
  {
    name: "white",
    label: "White",
    style: {
      "--color-primary": "#ffffff",
      "--color-secondary": "#000000",
      "--class": "white"
    },
  },
];

const messages = defineMessages({
  Target: {
    id: 'Target',
    defaultMessage: 'Target',
  },
  Slider: {
    id: 'Slider',
    defaultMessage: 'Slider',
  },
  Autoplay: {
    id: 'Autoplay',
    defaultMessage: 'Autoplay',
  },
  AutoplayEnabled: {
    id: 'Enable Autoplay?',
    defaultMessage: 'Autoscroll',
  },
  AutoplayDelay: {
    id: 'Delay between transitions (milliseconds)',
    defaultMessage: 'Delay (seconds)',
  },
  AutoplayJump: {
    id: 'Do instant slide transitions?',
    defaultMessage: 'Do instant slide transitions?',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  imageOverride: {
    id: 'Image override',
    defaultMessage: 'Image override',
  },
  item: {
    id: 'Item',
    defaultMessage: 'Item',
  },
  items: {
    id: 'Items',
    defaultMessage: 'Items',
  },
  addItem: {
    id: 'Add item',
    defaultMessage: 'Add item',
  },
  head_title: {
    id: 'Headtitle',
    defaultMessage: 'Headtitle',
  },
  autor: {
    id: 'Autor',
    defaultMessage: 'Autor'
  }
});

export const itemSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.item),
      addMessage: props.intl.formatMessage(messages.addItem),
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: [
            'href',
            'title',
            'description',
            'autor',
            'preview_image',
            'color',
            'button',
            'button_text'
          ],
        },
      ],

      properties: {
        href: {
          title: props.intl.formatMessage(messages.Target),
          widget: 'object_browser',
          mode: 'link',
          selectedItemAttrs: [
            'Title',
            'Description',
            'head_title',
            'hasPreviewImage',
            'image_field',
            'image_scales',
            '@type',
          ],
          allowExternals: true,
        },
        head_title: {
          title: props.intl.formatMessage(messages.head_title),
        },
        title: {
          title: props.intl.formatMessage(messages.title),
        },
        description: {
          title: props.intl.formatMessage(messages.description),
          widget: 'textarea',
        },
        autor: {
          title: props.intl.formatMessage(messages.autor),
        },
        preview_image: {
          title: props.intl.formatMessage(messages.imageOverride),
          widget: 'object_browser',
          mode: 'image',
          allowExternals: true,
        },
        button: {
          title: "show Button",
          type: 'boolean',
        },
        button_text: {
          title: "Button Text",
          widget: 'textarea',
        },
        color: {
          title: 'Farbe',
          type: 'string',
          widget: 'color_picker',
          colors: colors
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.slider.extensions?.slideSchema || {},
  );

export const SliderSchema = (props) =>
  mergeSchemas(
    {
      title: props.intl.formatMessage(messages.Slider),
      block: 'slider',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['autoplayEnabled', 'autoplayDelay', 'autoplayJump', 'slides'],
        }
        ,
      ],
      properties: {
        slides: {
          widget: 'object_list',
          title: props.intl.formatMessage(messages.items),
          schema: itemSchema(props),
          activeObject: props.activeObject,
          setActiveObject: props.setActiveObject,
          default: [{ '@id': uuid() }],
        },
        autoplayEnabled: {
          title: props.intl.formatMessage(messages.AutoplayEnabled),
          default: false,
          type: 'boolean',
        },
        autoplayDelay: {
          title: props.intl.formatMessage(messages.AutoplayDelay),
          type: 'integer',
          default: 4,
        },
        autoplayJump: {
          title: props.intl.formatMessage(messages.AutoplayJump),
          default: false,
          type: 'boolean',
        },
      },
      required: [],
    },
    config.blocks.blocksConfig.slider.extensions?.blockSchema || {},
  );
