/**
 * File view component.
 * @module components/theme/View/FileView
 */

import React, { useState, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import leftIcon from './arrow-left.svg';
import rightIcon from './arrow-right.svg';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

/**
 * File view component class.
 * @function FileView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const PdfView = ({ content }) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [windowWidth, setWindowWidth] = useState(() => {
        if (typeof window !== 'undefined') {
            return window.innerWidth;
        } else {
            return 0; // Or any default value you prefer
        }
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        if (typeof window !== 'undefined') {
            window.addEventListener('resize', handleResize);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []); // Empty dependency array ensures that this effect runs only once


    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const goToPreviousPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const goToNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    return (
        <>
            <Document
                file={content.file.download}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    scale={2}
                    canvasBackground={'white'}
                    width={windowWidth < 1300 ? windowWidth * 0.4 : 580}
                />
            </Document>
            <div className='pdf-controls'>
                <button onClick={goToPreviousPage} className={`pdf-button ${pageNumber === 1 ? "disabled" : ""}`}>
                    <span className="icon" style={{ backgroundImage: `url(${leftIcon})` }}></span>
                </button>
                <span className='pageNum'>{`Seite ${pageNumber} von ${numPages}`}</span>
                <button onClick={goToNextPage} className={`pdf-button ${pageNumber === numPages ? "disabled" : ""}`}>
                    <span className="icon" style={{ backgroundImage: `url(${rightIcon})` }}></span>
                </button>
            </div>

        </>
    );
};
export default PdfView;
