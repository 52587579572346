const colors = [
  {
    name: "red",
    label: "Red",
    style: {
      "--color-primary": "#aa001e",
    },
  },
  {
    name: "grey",
    label: "Grey",
    style: {
      "--color-primary": "#dbe1e9",
    },
  },
  {
    name: "beige",
    label: "Beige",
    style: {
      "--color-primary": "#E6C8A5",
    },
  },
  {
    name: "lightgrey",
    label: "Lightgrey",
    style: {
      "--color-primary": "#f2f4f7",
    },
  }
];


export function GridSchema(props) {
  const schema = {
    title: "Schema",
    fieldsets: [
      {
        id: 'default',
        title: 'Default',
        fields: ['color', 'text', 'space'],
      },
    ],

    properties: {
      color: {
        title: 'Farbe',
        type: 'string',
        widget: 'color_picker',
        colors: colors
      },
      text: {
        title: 'Beschreibung',
        type: 'string',
      },
      space: {
        title: 'Abstand',
        type: 'boolean',
      },
    },
    required: [],
  }
  return schema;
};

export default GridSchema;
