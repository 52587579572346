export const CellSchema = () => ({
  title: "Grid Cell",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'image','alt', 'link'],
    },
  ],

  properties: {
    text: {
      title: 'Text',
      type: 'string',
    },
    image: {
      title: 'Image',
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    alt: {
      title: 'Image alt Text',
      type: 'string',
    },
    link: {
      title: 'url',
      type: 'string',
    },
  },
  required: [],
});

export const EditSchema = (intl) => ({
  title: "Schema",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'content',
      title: "Zellen hinzu",
      fields: ['cells'],
    },
  ],

  properties: {
    cells: {
      title: "Cells",
      schema: CellSchema(intl),
      widget: 'object_list',
    },
  },
  required: [],
});
