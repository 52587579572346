// SemanticUI-free pre-@plone/components
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Container } from '@plone/components';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import { useIntl, defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import cx from 'classnames';
import heartIcon from './heart-fill.svg';
import { Link } from 'react-router-dom';
import {
  Anontools,
  LanguageSelector,
  Logo,
  Navigation,
  SearchWidget,
  UniversalLink,
} from '@plone/volto/components';
import { format } from 'prettier';

const messages = defineMessages({
  siteLabel: {
    id: 'siteLabel',
    defaultMessage: ' ',
  },
});

const InternetHeader = ({ pathname, siteLabel, token, siteAction }) => {

  const loadAltruja = () => {
    var body = document.getElementsByTagName('body')[0];
    if (!body) {
      window.setTimeout(integrate, 10);
      return;
    }
    var script = document.createElement('script');
    script.src = "https://altruja.de/i/ksrz";
    // body.insertBefore(script, body.lastChild);
    body.insertBefore(script, body.firstChild);

  }

  useEffect(() => {
    loadAltruja();
  }, [])

  return (
    <>

      <div className="header">
        <div className="logo-nav-wrapper">
          <div className="logo">
            <Logo />
            {/* <div className="logo_text">
              <UniversalLink href={"/"} >
                <span>
                  Stiftung
                  <br />
                  Hochschulmedizin
                  <br />
                  Dresden
                  <br />
                </span>
              </UniversalLink>
            </div> */}
          </div>
          <Navigation pathname={pathname} />
          <MobileNavigation pathname={pathname} />
          <div className="search-wrapper navigation-desktop">
            <div className="search">
              <SearchWidget />
            </div>
          </div>
        </div>

      </div >
    </>
  );
};

const Header = (props) => {
  const { pathname } = props;
  let siteLabel = config.settings.siteLabel;
  const intranetHeader = config.settings.intranetHeader;
  const token = useSelector((state) => state.userSession.token);
  const siteAction = useSelector(
    (state) => state.content.data?.['@components']?.actions?.site_actions,
  );
  const intl = useIntl();
  const translatedSiteLabel = intl.formatMessage(messages.siteLabel);

  siteLabel =
    siteLabel &&
    (translatedSiteLabel !== 'siteLabel' && translatedSiteLabel !== ' '
      ? translatedSiteLabel
      : siteLabel);

  return (
    <>
      <div className='sticky-try'>
        <div className="tools-wrapper">
          <Container layout>
            <LanguageSelector />
            <div className="tools-bar">
              <div className="tools">
                {!token && <Anontools />}
                {/*siteAction &&
              siteAction.map((item) => (
                <UniversalLink key={item.url} href={item.url}>
                  {item.title}
                </UniversalLink>
              ))*/}
                <UniversalLink href={"/kontakt"}>
                  {"Kontakt"}
                </UniversalLink>
                <UniversalLink href={"/impressum"}>
                  {"Impressum"}
                </UniversalLink>
              </div>
              <a id="ef-bl-x7jn2nd9j"
                href="/spenden/?ef-page=1&spendenstiftung"
                className="spenden-button">
                {"Spenden"}
                <span
                  className="heart-icon"
                  style={{ backgroundImage: `url(${heartIcon})` }}>
                </span>
              </a>
            </div>
          </Container>
        </div>
      </div>
      <header
        className={cx('header-wrapper', { 'intranet-header': intranetHeader })}
      >
        <Container layout>
          <InternetHeader
            pathname={pathname}
            siteLabel={siteLabel}
            token={token}
            siteAction={siteAction}
          />
        </Container>
      </header>
    </>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
