import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import rightIcon from './chevron-right.svg';
import { Image } from 'semantic-ui-react';
import { FormattedDate } from '@plone/volto/components';

const HomepageNewsKacheln = ({ items, isEditMode }) => {

  const shortenString = (str, maxLength) => {
    if (!str) return '';
    if (str.length <= maxLength) return str;
    // don't destroy full words
    let shortenedStr = str.substring(0, maxLength);
    // Trim the string to avoid cutting off in the middle of a word
    shortenedStr = shortenedStr.substr(0, Math.min(shortenedStr.length, shortenedStr.lastIndexOf(" ")));
    return shortenedStr + ' ...';
  };

  return (
    <>
      <div className="items">
        <div className="ui twelve column grid column-grid">
          {items.map((item, index) => (
            <div key={index} className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper listing-container">
              <ConditionalLink item={item} condition={!isEditMode}>
                {item.image && <Image src={item.image.scales.teaser.download} alt={item.alt || null} />}
                <div className='listing-kachel'>
                  <h3>{item.title ? shortenString(item.title, 50) : item.id}</h3>
                  {item.description && (
                    <p>{shortenString(item.description, 120)}</p>
                  )}
                  {item.effective && (<p className='datum'>
                    <FormattedDate date={item.effective} />
                  </p>)}
                </div>
              </ConditionalLink>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

HomepageNewsKacheln.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEditMode: PropTypes.bool,
};

export default HomepageNewsKacheln;
