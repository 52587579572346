/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */


// All your imports required for the config here BEFORE this line
import '@plone/volto/config';

// import { HomepageNewsKacheln } from './customizations/components/manage/Blocks/Listing';
// import { PdfListing } from './customizations/components/manage/Blocks/Listing';

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config.views = {
    ...config.views,
    contentTypesViews: {
      ...config.views.contentTypesViews,
    },
  };
  // config.settings = {
  //   ...config.settings,
  //   logo: '/customisations/components/theme/Logo/Logo.png',
  //   devProxyToApiPath: 'http://localhost:8080/StiftungHochschulmedizin',
  //   //slate: {
  //   //  ...config.settings.slate,
  //   //  toolbarButtons: [...config.settings.slate.toolbarButtons, 'align-center'],
  //   //},

  // };
  config.blocks.requiredBlocks = [];
  //{ console.log(config.settings) }
  // config.blocks.blocksConfig.listing.variations = [
  //   ...config.blocks.blocksConfig.listing.variations,
  //   {
  //     id: "HomepageNewsKacheln",
  //     title: "Homepage News Kacheln",
  //     template: HomepageNewsKacheln,
  //     fullobjects: true,
  //   },
  //   {
  //     id: "PdfListing",
  //     title: "PDF Liste",
  //     template: PdfListing,
  //     fullobjects: true,
  //   },
  // ];
  //config.settings.enableFatMenu = true;
  //config.settings.siteLabel = 'Plone Intranet';

  config.settings.supportedLanguages = ['de', 'en'];
  config.settings.defaultLanguage = 'de';

  return config;
}
