import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, Component } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Document, Page } from 'react-pdf';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';


const PreviewImage = ({ url }) => {
  return (
    <div>
      <Document
        file={url}
      >
        <Page
          pageNumber={1}
          renderTextLayer={false}
          renderAnnotationLayer={false}
          scale={2}
          canvasBackground={'white'}
          width={100}
          height="100px"
        />
      </Document>
    </div>
  );
};

const PdfListing = ({ items, linkTitle, linkHref, isEditMode }) => {
  return (
    <>
      <div className="items news-wrapper pdf-list">
        <div className="ui twelve column grid column-grid">

          {items.map((item, index) => (
            <div key={index} className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper listing-container">

              <ConditionalLink item={item} condition={!isEditMode}>
                <div className="preview-row">
                  <PreviewImage url={item.url + "/@@download/file"} alt="" />
                </div>
                <h4>{item.title ? item.title : item.id}</h4>

              </ConditionalLink>

            </div>
          ))}
        </div>
      </div>
    </>
  );
};

PdfListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default PdfListing;
